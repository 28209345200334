
export default {
    methods: {
        extractProxyPath (url) {
            const proxyIndex = url.indexOf('/proxy/');
            return proxyIndex !== -1 ? url.substring(proxyIndex) : null;
        },
        transformUrl (originalUrl) {
            if (!originalUrl) return '';
            const insertPart = '/gateway/model-manager/api/v1';
            const proxyPath = this.extractProxyPath(originalUrl);
            return proxyPath ? insertPart + proxyPath : originalUrl;
        },
    },

}
