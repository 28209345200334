

<template>
  <div class='voiceClone'>
    <div class="input_content_wrap">
      <div class="common_title">
        <div class="vertical_line"></div>
        <div class="title">语音输入</div>
      </div>
      <div class="type_wrap">
        <div class="type_title">类别 :</div>
        <div class="item_list">
          <div class="type_item" v-for="(item,index) in languages" :key="index"
            :class="{ active: prompt_language === item.value }" @click="setPromptLanguage(item.value)">
            {{item.name}}
          </div>
        </div>
      </div>
      <div class="opreation_wrap">
        <div class="start_wrap">
          <div class="img_wrap">
            <template v-if="recording">
              <div @click="stopRecordAudio">
                <img src="@/assets/images/voice/inRecognition.png" alt="">
                <span>点击停止录音</span>
              </div>
            </template>
            <template v-else>
              <div @click="startRecordAudio">
                <img src="@/assets/images/voice/start.png" alt="">
                <span>开始录音</span>
              </div>
            </template>
          </div>
        </div>
        <div class="hint">点击“话筒”开始录音,我可以克隆你的声音哦请允许浏览器获取麦克风权限</div>
      </div>
      <div class="voice_file" v-if="showText">
        <span @click="playRecordAudio">{{showText}}</span>
        <img  @click="showText=''" src="@/assets/images/voice/delete.png" alt="">
      </div>
    </div>
    <div class="test_text_wrap">
      <div class="common_title">
        <div class="vertical_line"></div>
        <div class="title">测试文本</div>
      </div>
      <div class="type_wrap">
        <div class="type_title">类别 :</div>
        <div class="item_list">
          <div class="type_item" v-for="(item,index) in languages" :key="index"
            :class="{ active: text_language === item.value }" @click="setTextLanguage(item.value)">
            {{item.name}}
          </div>
        </div>
      </div>
      <div class="test_text">
        <el-input type="textarea" :rows="25"  show-word-limit maxlength="150" placeholder="请输入待测试文本" v-model="testText">
        </el-input>
      </div>
    </div>
    <div class="clone_wrap">
      <audio ref="audioPlayer" controls class="audio_warp" v-if="audioUrl" :key="audioKey">
        <source :src="audioUrl" type="audio/wav">
        Your browser does not support the audio element.
      </audio>
      <img class="wait" src="@/assets/images/voice/wait.png" alt="">
      <div class="startClone" v-if="!loading" :class="{'complete':startClone}" @click="handleStartClone">
        开始克隆
      </div>
      <div v-if="loading" class="Cloning">
        克隆中...
      </div>
      <!-- <div class="startClone complete">
      </div> -->
    </div>

  </div>
</template>

<script>
import file2base64 from '@/utils/file2base64'
import { v4 as uuidv4 } from 'uuid';
import api from '@/api/api'
import pathMixin from './proxyPathMixin'
import Recorder from 'js-audio-recorder';
import axios from 'axios';
export default {
  mixins: [pathMixin],
  props: {
    info: {
      type: Object,
      default: () => { }
    },
  },
  data () {
    return {
      imageUrl: '',
      loading: false,
      languages: [
        {
          name: '普通话',
          value: 'zh'
        },
        // {
        //   name: '英语',
        //   value: 'en'
        // },
        // {
        //   name: '日语',
        //   value: 'all_ja'
        // }
      ],
      prompt_language: 'zh',//语音语种
      text_language: 'zh',//文本语种
      fileList: [],
      testText: '',
      recorder: new Recorder({
        sampleBits: 16,
        sampleRate: 48000,
        numChannels: 1,
      }),
      recording: false,
      showText: '',
      pause: false,
      audioUrl: null,
      audioKey: 0
    }
  },
  computed: {
    startClone () {
      return this.testText.trim().length > 0 && this.showText
    }
  },
  watch: {
    info: {
      handler (newValue) {
        console.log(newValue, 'newValue')
      }
    }
  },
  methods: {
    setPromptLanguage (value) {
      this.prompt_language = value;
    },
    setTextLanguage (value) {
      this.text_language = value;
    },
    startRecordAudio () {
      Recorder.getPermission().then(
        () => {
          console.log("开始录音");
          this.recorder.start(); // 开始录音
          this.recording = true;
          this.showText = ''
        },
        (error) => {
          this.$message({
            message: "请先允许该网页使用麦克风",
            type: "info",
          });
          console.log(`${error.name} : ${error.message}`);
        }
      );
    },
    stopRecordAudio () {
      console.log("停止录音");
      this.recorder.stop();
      this.recording = false;
      this.showText = '录音试听'
    },
    //播放录音
    playRecordAudio () {
      this.recorder.play();
    },
    async handleStartClone () {
      if (!this.startClone) return
      let wavBlob = this.recorder.getWAVBlob();
      let formData = new FormData()
      const newbolb = new Blob([wavBlob], { type: 'audio/wav' })
      const fileOfBlob = new File([newbolb], new Date().getTime() + '.wav')
      formData.append('input_audio', fileOfBlob)
      formData.append('prompt_language', this.prompt_language)
      formData.append('input_text', this.testText.replace(/\s+/g, ''));
      formData.append('text_language', this.text_language)
      for (let [key, value] of formData.entries()) {
        console.log(`${key}:`, value);
      }
      const url = this.info?.appDetailAddVo?.url;
      if (!url) {
        this.$message.error('URL不存在');
        return;
      }
      const apiTestUrl = this.extractProxyPath(url);
      if (!apiTestUrl) {
        this.$message.error('无法提取URL中的/proxy/部分');
        return;
      }
      this.loading = true;
      this.audioUrl = ''
      this.audioKey++;
      axios.post(`/apitest${apiTestUrl}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        responseType: 'blob'
      })
        .then(response => {
          const audioUrl = URL.createObjectURL(response.data);
          this.audioUrl = audioUrl;
          this.loading = false;
          this.$notify({
            title: '提 示',
            message: '克隆成功',
            type: 'success'
          });
          this.$nextTick(() => {
            this.playAudio();
          })
        }).catch((e) => {
          this.$notify({
            title: '提 示',
            message: '克隆失败',
            type: 'error'
          });

        }).finally(() => {
          this.loading = false
        })
    },
    playAudio () {
      this.$refs.audioPlayer.load();

    }
  },


}
</script>

<style lang="scss" scoped>
::v-deep.voiceClone {
  width: 1024px;
  height: 628px;
  box-sizing: border-box;
  position: relative;
  background: #f5f5f5;
  border-radius: 4px;
  padding: 16px;
  display: flex;
  justify-content: space-around;
  padding-right: 0px;
  .common_title {
    margin-top: 2px;
    display: flex;
    align-items: center;
    .vertical_line {
      width: 4px;
      height: 17px;
      background: #2874f7;
    }
    .title {
      font-size: 18px;
      font-weight: 500;
      color: #333333;
      margin-left: 7px;
    }
  }
  .type_wrap {
    display: flex;
    margin-top: 16px;
    align-items: center;
    .type_title {
      font-size: 14px;
      font-weight: 500;
      color: #a8a8a8;
      margin-right: 32px;
    }
    .item_list {
      display: flex;
      justify-content: space-between;
    }
    .type_item {
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      padding: 10px 16px;
      margin-right: 10px;
      cursor: pointer;
    }
    .active {
      background: #2774f7;
      border-radius: 4px;
      color: #fff;
    }
  }
  .input_content_wrap {
    width: 320px;
    position: relative;

    .opreation_wrap {
      text-align: center;
      box-sizing: border-box;
      padding: 27px;
      margin-top: 70px;
      .img_wrap {
        position: relative;
        width: 160px;
        height: 160px;
        margin: 0 auto;
        margin-bottom: 20px;

        img {
          width: 160px;
          height: 160px;
          cursor: pointer;
        }
        span {
          position: absolute;
          left: 50%;
          top: 75%;
          transform: translate(-50%, -50%);
          font-size: 12px;
          font-weight: 500;
          text-align: center;
          color: #2774f7;
          cursor: pointer;
        }
      }
      .hint {
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        color: #a8a8a8;
        margin-top: 16px;
        line-height: 20px;
      }
    }

    .voice_file {
      box-sizing: border-box;
      position: absolute;
      bottom: 2px;
      width: 306px;
      height: 36px;
      background: #ffffff;
      border: 1px solid #cfd3da;
      border-radius: 2px;
      font-size: 14px;
      color: #333333;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 11px 16px;
      img {
        width: 14px;
        height: 14px;
        cursor: pointer;
      }
      span {
        cursor: pointer;
        color: #2774f7;
      }
    }
  }
  .test_text_wrap {
    .test_text {
      width: 380px;
      box-sizing: border-box;
      height: 510px;
      background: #ffffff;
      border: 1px solid #cfd3da;
      border-radius: 2px;
      margin-top: 10px;
      overflow-y: auto;
      .el-textarea {
        padding: 0px;
        box-sizing: border-box;
        textarea {
          border: none;
        }
      }
    }
  }
  .clone_wrap {
    flex: 1;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    .audio_warp{
      width: 100%;
    }
    .wait {
      width: 200px;
      height: 92px;
      border-radius: 4px;
    }
    .startClone,
    .Cloning {
      position: absolute;
      bottom: 5px;
      display: flex;
      width: 258px;
      height: 38px;
      background: url("~@/assets/images/voice/btn_gray.png");
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #ffffff;
      cursor: pointer;
    }
    .Cloning {
      background: #ffffff;
      border: 1px solid #2774f7;
      border-radius: 4px;
      font-size: 14px;
      color: #2774f7;
    }
    .complete {
      background: url("~@/assets/images/voice/btn_blue.png");
    }
  }
}
</style>