

<template>
  <div class='voice2text'>
    <div class="input_content_wrap">
      <div class="common_title">
        <div class="vertical_line"></div>
        <div class="title">语音输入</div>
      </div>
      <div class="type_wrap">
        <div class="type_title">类别 :</div>
        <div class="item_list">
          <div class="type_item" v-for="(item,index) in languages" :key="index"
            :class="{ active: activeIndex === index }" @click="setActive(index)">
            {{item.name}}
          </div>
        </div>
      </div>
      <div class="opreation_wrap">
        <div class="start_wrap">
          <div class="img_wrap">
            <template v-if="recording">
              <div @click="stopRecordAudio">
                <img src="@/assets/images/voice/inRecognition.png" alt="">
                <span>点击停止录音</span>
              </div>
            </template>
            <template v-else>
              <div @click="startRecordAudio">
                <img src="@/assets/images/voice/start.png" alt="">
                <span>开始录音</span>
              </div>
            </template>
          </div>
          <div class="hint">点击“开始录音”开始录音，请对我说你想说的话，我可以识别你说的内容哦~ 请允许浏览器获取表克风权限</div>
          <div class="voice_file" v-if="showText">
            <span @click="playRecordAudio">{{showText}}</span>
            <img  @click="showText=''" src="@/assets/images/voice/delete.png" alt="">
          </div>
          <!-- <el-upload show-file-list :file-list="fileList" drag :on-remove="handleRemove" :http-request="uploadVoiceFile"
            action="" accept=".mp3,.wav">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">支持格式为：WAV、MP3等，小于5MB</div>
          </el-upload> -->
        </div>
      </div>
    </div>
    <div class="result_content_wrap">
      <div class="common_title">
        <div class="vertical_line"></div>
        <div class="title">识别结果</div>
      </div>
      <div class="result_content" v-loading="loading">
        <p>{{testResult}}</p>
        <el-button type="primary" @click="startRecognition" class="common-btn" :disabled="!isReady">开始识别</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import file2base64 from '@/utils/file2base64'
import { v4 as uuidv4 } from 'uuid';
import api from '@/api/api'
import pathMixin from './proxyPathMixin';
import Recorder from 'js-audio-recorder';
export default {
  mixins: [pathMixin],
  props: {
    info: {
      type: Object,
      default: () => { }
    },

  },
  data () {
    return {
      imageUrl: '',

      loading: false,
      languages: [
        {
          name: '中文普通话',
          value: 1
        },
        // {
        //   name: '英语',
        //   value: 2
        // },
        // {
        //   name: '其他语种',
        //   value: 3
        // },
        // {
        //   name: '方言',
        //   value: 4
        // },
        // {
        //   name: '民族语言',
        //   value: 5
        // }
      ],
      activeIndex: 0,
      fileList: [],
      testResult: '',
      recorder: new Recorder({
        sampleBits: 16,
        sampleRate: 48000,
        numChannels: 1,
      }),
      recording: false,
      showText: ''
    }
  },
  computed: {
    isReady () {
      return this.showText.length > 0
    }
  },
  watch: {
    info: {
      handler (newValue) {
        console.log(newValue, 'newValue')
      }
    }
  },
  methods: {
    setActive (index) {
      this.activeIndex = index;
    },
    handleRemove (file, fileList) {
      this.fileList = [];
    },
    uploadVoiceFile (params) {
      this.fileList = [params.file]
    },
    async startRecognition_bak () {
      this.testResult = ''
      // console.log(this.fileList, 'fffffff')
      // console.log(this.info, 'infoinfoinfo')
      const url = this.info?.appDetailAddVo?.url;
      if (!url) {
        this.$message.error('URL不存在');
        return;
      }
      if (!this.fileList.length) {
        return this.$notify({
          title: '提 示',
          message: '请上传语音文件',
          type: 'warning'
        });
      }

      const apiTestUrl = this.extractProxyPath(url);
      const recordUrl = this.transformUrl(url);
      //api.testRecord(recordUrl); // 日志记录
      let formData = new FormData()
      formData.append('audio_file', this.fileList[0])
      this.loading = true;
      try {
        const result = await api.modelApiTest(apiTestUrl, formData)
        this.testResult = result?.audio_result || ''
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    async startRecognition () {

      if (!this.isReady) return;
      console.log("FFFFFFFFFFFFF")
      this.testResult = '';


      const url = this.info?.appDetailAddVo?.url;
      if (!url) {
        this.$message.error('URL不存在');
        return;
      }
      let wavBlob = this.recorder.getWAVBlob();
      let formData = new FormData()
      const newbolb = new Blob([wavBlob], { type: 'audio/wav' })
      const fileOfBlob = new File([newbolb], new Date().getTime() + '.wav')

      const apiTestUrl = this.extractProxyPath(url);
      const recordUrl = this.transformUrl(url);
      //api.testRecord(recordUrl); // 日志记录
      formData.append('audio_file', fileOfBlob)
      this.loading = true;
      try {
        const result = await api.modelApiTest(apiTestUrl, formData)
        this.testResult = result?.audio_result || ''
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },
    startRecordAudio () {
      Recorder.getPermission().then(
        () => {
          console.log("开始录音");
          this.recorder.start(); // 开始录音
          this.recording = true;
          this.showText = ''
        },
        (error) => {
          this.$message({
            message: "请先允许该网页使用麦克风",
            type: "info",
          });
          console.log(`${error.name} : ${error.message}`);
        }
      );
    },
    stopRecordAudio () {
      console.log("停止录音");
      this.recorder.stop();
      this.recording = false;
      this.showText = '录音试听'
    },
    playRecordAudio () {
      this.recorder.play();
    },
  },

}
</script>

<style lang="scss" scoped>
::v-deep.voice2text {
  width: 1024px;
  height: 628px;
  box-sizing: border-box;
  position: relative;
  background: #f5f5f5;
  border-radius: 4px;
  padding: 16px;
  display: flex;
  .common_title {
    margin-top: 2px;
    display: flex;
    align-items: center;
    .vertical_line {
      width: 4px;
      height: 17px;
      background: #2874f7;
    }
    .title {
      font-size: 18px;
      font-weight: 500;
      color: #333333;
      margin-left: 7px;
    }
  }
  .input_content_wrap {
    flex: 1;
    .type_wrap {
      display: flex;
      margin-top: 16px;
      align-items: center;
      .type_title {
        font-size: 14px;
        font-weight: 500;
        color: #a8a8a8;
        margin-right: 32px;
      }
      .item_list {
        display: flex;
        justify-content: space-between;
      }
      .type_item {
        font-size: 14px;
        font-weight: 500;
        color: #000000;
        padding: 10px 16px;
        margin-right: 10px;
        cursor: pointer;
      }
      .active {
        background: #2774f7;
        border-radius: 4px;
        color: #fff;
      }
    }
    .opreation_wrap {
      text-align: center;
      box-sizing: border-box;
      padding: 63px;

      .img_wrap {
        position: relative;
        width: 160px;
        height: 160px;
        margin: 0 auto;
        margin-bottom: 20px;
        img {
          width: 160px;
          height: 160px;
          cursor: pointer;
        }
        span {
          position: absolute;
          left: 50%;
          top: 75%;
          transform: translate(-50%, -50%);
          font-size: 12px;
          font-weight: 500;
          text-align: center;
          color: #2774f7;
          cursor: pointer;
        }
      }
      .hint {
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        color: #a8a8a8;
        line-height: 30px;
        margin-top: 16px;
      }
      .voice_file {
        box-sizing: border-box;
        bottom: 2px;
        width: 306px;
        height: 36px;
        background: #ffffff;
        border: 1px solid #cfd3da;
        border-radius: 2px;
        font-size: 14px;
        color: #333333;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;
        margin-top: 50px;
        padding: 11px 16px;
        img {
          width: 14px;
          height: 14px;
          cursor: pointer;
        }
        span {
          cursor: pointer;
          color: #2774f7;
        }
      }
    }
  }
  .result_content_wrap {
    .result_content {
      box-sizing: border-box;
      width: 440px;
      height: 562px;
      background: #ffffff;
      border: 1px solid #cfd3da;
      border-radius: 2px;
      margin-top: 10px;
      padding: 8px;
      overflow-y: auto;
      position: relative;
      p {
        line-height: 20px;
        font-size: 14px;
      }
      .el-button {
        position: absolute;
        right: 10px;
        bottom: 10px;
      }
    }
  }
}
</style>