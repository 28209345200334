

<template>
  <div class='chartWrap'>
    <div class="multi_modal_img" style="position: relative;">
      <img v-if="imageUrl" class="temp_img" :src="imageUrl">
      <el-upload action="" drag :auto-upload="true" :show-file-list="false" :before-upload="handleBeforeUpload"
        :multiple="false" :limit="1" accept="image/x-png,image/jpeg" :style="{
          position: 'absolute', 
          top: 0, 
          left: 0, 
          width: '100%', 
          height: '100%', 
          opacity: imageUrl ? 0 : 1
        }">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将图片拖到此处，或<em>点击上传</em></div>
      </el-upload>
    </div>
    <div class="result_content_wrap">
      <div class="result_top">
        <div class="vertical_line"></div>
        <div class="title">识别结果</div>
      </div>
      <div class="result_content" v-loading="loading">
        <el-image :src="ProcessedImageUrl" :preview-src-list="[ProcessedImageUrl]" v-if="ProcessedImageUrl"
          alt=""></el-image>
        <p>{{testResult}}</p>
        <el-button type="primary" @click="imageTest" class="common-btn test_btn">测试</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import file2base64 from '@/utils/file2base64'
import { v4 as uuidv4 } from 'uuid';
import api from '@/api/api'
export default {
  props: {
    info: {
      type: Object,
      default: () => { }
    },

  },
  data () {
    return {
      imageUrl: '',
      testResult: '',
      loading: false,
      ProcessedImageUrl: ''
    }
  },

  watch: {
    info: {
      handler (newValue) {
        console.log(newValue, 'newValue')
      }
    }
  },
  methods: {
    handleBeforeUpload (file) {
      file2base64(file).then(res => {
        this.imageUrl = res;
        console.log(this.imageUrl, 'this.imageUrl')
      }, err => {
        if (err === 'TYPE_INVALID') {
          this.$message.error('图片转base64失败');
        }
      });
      return false;
    },
    urlSateBase64Encode (base64Str) {
      if (!base64Str) return;
      let safeStr = base64Str.replace(/\+/g, '-').replace(/\//g, '_').replace(/\=/g, '');
      return safeStr;
    },
    imageTest () {

      if (!this.imageUrl) {
        this.$message.error('请选择图片');
        return;
      }
      const url = this.info?.appDetailAddVo?.url;
      if (!url) {
        this.$message.error('URL不存在');
        return;
      }
      const apiTestUrl = this.extractProxyPath(url);
      if (!apiTestUrl) {
        this.$message.error('无法提取URL中的/proxy/部分');
        return;
      }
      this.testResult = ''
      this.loading = true;
      let promise;
      const recordUrl = this.transformUrl(url);
      //api.testRecord(recordUrl); // 日志记录
      promise = api.modelApiTest(apiTestUrl, {
        image2array: 1,
        resNet50: 1,
        instances: [
          { image_b64: this.urlSateBase64Encode(this.imageUrl.match(/base64,(\S*)/)[1]) }
        ]
      })
      promise.then(res => {
        if (this.info.sceneTagNames[0] == '通用目标检测') {
          this.drawCanvas(this.imageUrl, res.outputs).then(({ data }) => {
            this.ProcessedImageUrl = data
            this.loading = false;
          })
        } else if (this.info.sceneTagNames[0] == '通用分类') {
          console.log(res, 'resresresres')
          // this.testResult = JSON.stringify(res.class, null, '\t');
          this.testResult = res.class;
          this.loading = false;
        } else if (this.info.sceneTagNames[0] == '通用图像分割' || this.info.sceneTagNames[0] == '语义分割') {
          this.ProcessedImageUrl = 'data:image/jpeg;base64,' + res.result_img
          this.loading = false;
        }
      }, err => {
        this.testResult = "";
        this.loading = false;
      })
    },
    extractProxyPath (url) {
      const proxyIndex = url.indexOf('/proxy/');
      return proxyIndex !== -1 ? url.substring(proxyIndex) : null;
    },
    transformUrl (originalUrl) {
      if (!originalUrl) return '';
      const insertPart = '/gateway/model-manager/api/v1';
      const proxyPath = this.extractProxyPath(originalUrl);
      return proxyPath ? insertPart + proxyPath : originalUrl;
    },
    drawCanvas (src, list) {
      return new Promise(async (resolve, reject) => {
        let img = new Image();
        img.crossOrigin = ""
        img.src = src
        img.onload = () => {
          if (!img) {
            resolve({})
            return
          }
          let canvas = document.createElement('canvas')
          canvas.width = img.width
          canvas.height = img.height

          const context = canvas.getContext("2d")
          context.drawImage(img, 0, 0);

          context.lineWidth = 3;
          context.strokeStyle = 'red';

          for (let i = 0; i < list.length; i++) {
            const info = list[i]
            context.strokeRect(info.xmin, info.ymin, info.xmax - info.xmin, info.ymax - info.ymin);

            context.font = "normal 24px Verdana";

            const value = (info.confidence * 100).toFixed(1)
            let y = info.ymin - 10
            if (y < 10) y = info.ymax + 20
            let x = info.xmin
            var txt = info.name + ": " + value + "%"
            let width = context.measureText(txt).width
            if (x + width + 10 > img.width) x = img.width - width - 15


            context.fillStyle = "red";//填充背景
            context.fillRect(x, y - 15, width + 10, 30);

            context.fillStyle = "white";
            context.fillText(txt, x + 5, y + 5);
          }
          const data = canvas.toDataURL("image/png", 0.7)
          canvas = null
          // img = null
          // this.imageURL = data
          // this.loading = false;
          resolve({ data })
        }
      })
    },
  },

}
</script>

<style lang="scss" scoped>
::v-deep.chartWrap {
  width: 1024px;
  height: 628px;
  box-sizing: border-box;
  position: relative;
  background: #f5f5f5;
  border-radius: 4px;
  padding: 16px;
  .multi_modal_img {
    width: 100%;
    height: 200px;
    background: #f5f5f5;
    display: flex;
    align-items: center;
    justify-content: center;
    .el-upload {
      height: 180px;
      .el-upload-dragger {
        height: 180px;
      }
    }
    .temp_img {
      max-width: 250px;
      max-height: 170px;
      margin-bottom: 10px;
    }
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .result_content_wrap {
    .result_top {
      margin-top: 2px;
      display: flex;
      align-items: center;
      .vertical_line {
        width: 4px;
        height: 17px;
        background: #2874f7;
      }
      .title {
        font-size: 16px;
        font-weight: 500;
        color: #333333;
        margin-left: 7px;
      }
    }
    .result_content {
      box-sizing: border-box;
      position: relative;
      margin-top: 15px;
      width: 992px;
      height: 360px;
      background: #ffffff;
      border: 1px solid #cfd3da;
      padding: 2px;
      border-radius: 2px;
      display: flex;

      .test_btn {
        position: absolute;
        right: 10px;
        bottom: 10px;
      }
      p {
        font-size: 14px;
      }
      .el-image {
        max-width: 960px;
        max-height: 350px;
        img {
          max-width: 960px;
          max-height: 350px;
        }
      }
    }
  }
}
</style>