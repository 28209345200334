export default function file2base64(file) {
    let _resolve, _reject;
    if(!/image\/\w+/.test(file.type)){
        _reject('TYPE_INVALID');
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function(e){
        _resolve(e.target.result);
    };
    return new Promise((resolve, reject) => {
        _resolve = resolve;
        _reject = reject;
    });
}
