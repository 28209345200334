

<template>
  <div class='voiceSynthesis'>
    <div class="test_text_wrap">
      <div class="common_title">
        <div class="vertical_line"></div>
        <div class="title">文本输入</div>
      </div>
      <div class="test_text">
        <el-input type="textarea" maxlength="150"
        show-word-limit :rows="28" placeholder="请输入待测试文本" v-model="input_text">
        </el-input>
      </div>
    </div>
    <div class="toneSelect_wrap">
      <div class="common_title">
        <div class="vertical_line"></div>
        <div class="title">音色选择</div>
      </div>
      <div class="toneSelect">
        <div class="tone_item" v-for="(item,index) in toneList" :key="index" :class="{ active: activeIndex === index }"
          @click="setToneIndex(index)">
          <div class="item_left"></div>
          <div class="item_right">
            <div class="title">{{item.title}}</div>
            <div class="subTitle">{{item.subTitle}}</div>
          </div>
        </div>
      </div>
      <div class="voiceConfig">
        <div class="config_item">
          <div>音量</div>
          <el-slider v-model="volume" :show-tooltip="false"></el-slider>
          <div>{{volume}}</div>
        </div>
        <div class="config_item">
          <div>音调</div>
          <el-slider v-model="pitch" :show-tooltip="false"></el-slider>
          <div>{{pitch}}</div>
        </div>
        <div class="config_item">
          <div>语速</div>
          <el-slider v-model="speed" :show-tooltip="false"></el-slider>
          <div>{{speed}}</div>
        </div>
        <div class="config_item">
          <div>情绪</div>
          <el-select v-model="emotion">
            <el-option v-for="(item,index) in emotionList" :key="index" :label="item" :value="item">
            </el-option>
          </el-select>
        </div>
      </div>
    </div>

    <div class="operation_wrap">
      <audio ref="audioPlayer" controls class="audio_warp" v-if="audioUrl" :key="audioKey">
        <source :src="audioUrl" type="audio/wav">
        Your browser does not support the audio element.
      </audio>
      <img class="wait" src="@/assets/images/voice/wait.png" alt="">
      <div class="startAudition" v-if="!loading" :class="{'complete':startAudition}" @click="handleStartAudition">
        开始合成
      </div>
      <div v-if="loading" class="Cloning">
        合成中...
      </div>

    </div>

  </div>
</template>

<script>
import file2base64 from '@/utils/file2base64'
import { v4 as uuidv4 } from 'uuid';
import api from '@/api/api'
import pathMixin from './proxyPathMixin'
import Recorder from 'js-audio-recorder';
import axios from 'axios';
export default {
  mixins: [pathMixin],
  props: {
    info: {
      type: Object,
      default: () => { }
    },
  },
  data () {
    return {
      loading: false,
      input_text: '',
      toneList: [
        {
          title: '快乐小东',
          subTitle: '男生 活泼',
          toneId: 8051
        },
        {
          title: '快乐小南',
          subTitle: '男生 柔和',
          toneId: 11614
        },

        {
          title: '快乐幺鸡',
          subTitle: '男生 大叔',
          toneId: 6671
        },
        {
          title: '快乐红中',
          subTitle: '男生 深沉',
          toneId: 6670
        },
        {
          title: '快乐二条',
          subTitle: '女生 洪亮',
          toneId: 9136
        },

      ],
      emotionList: [
        '开心',
        '悲伤'
      ],
      activeIndex: 0,
      volume: 50,//音量
      pitch: 50,//音调
      speed: 50,//语速
      emotion: '开心',//情绪
      audioUrl: '',
      audioKey: 0
    }
  },
  computed: {
    startAudition () {
      return this.input_text.trim().length > 0
    }
  },
  watch: {
    info: {
      handler (newValue) {
        console.log(newValue, 'newValue')
      }
    }
  },
  methods: {
    setToneIndex (index) {
      this.activeIndex = index
    },
    handleStartAudition () {
      if (!this.startAudition) return
      const url = this.info?.appDetailAddVo?.url;
      if (!url) {
        this.$message.error('URL不存在');
        return;
      }
      const apiTestUrl = this.extractProxyPath(url);
      if (!apiTestUrl) {
        this.$message.error('无法提取URL中的/proxy/部分');
        return;
      }
      this.loading = true;
      this.audioUrl = ''
      this.audioKey++;
      const requestData = {
        instances: {
          input_text: this.input_text.replace(/\s+/g, ''),
          prompt: this.emotion,
          voice: this.toneList[this.activeIndex].toneId,
          volume:this.volume,
          pitch:this.pitch,
          speed:this.speed
        }
      };
      axios.post(`/apitest${apiTestUrl}`, requestData, {
        responseType: 'blob'
      })
        .then(response => {
          const audioUrl = URL.createObjectURL(response.data);
          this.audioUrl = audioUrl;
          this.loading = false;
          this.$notify({
            title: '提 示',
            message: '合成成功',
            type: 'success'
          });
          this.$nextTick(() => {
            this.$refs.audioPlayer.load();
          })
        }).catch((e) => {
          this.$notify({
            title: '提 示',
            message: '合成失败',
            type: 'error'
          });

        }).finally(() => {
          this.loading = false
        })
    }
  },


}
</script>

<style lang="scss" scoped>
::v-deep.voiceSynthesis {
  width: 1024px;
  height: 628px;
  box-sizing: border-box;
  position: relative;
  background: #f5f5f5;
  border-radius: 4px;
  padding: 16px 10px;
  display: flex;
  justify-content: space-around;

  .common_title {
    margin-top: 2px;
    display: flex;
    align-items: center;
    .vertical_line {
      width: 4px;
      height: 17px;
      background: #2874f7;
    }
    .title {
      font-size: 18px;
      font-weight: 500;
      color: #333333;
      margin-left: 7px;
    }
  }

  .test_text_wrap {
    .test_text {
      width: 420px;
      box-sizing: border-box;
      height: 562px;
      background: #ffffff;
      border: 1px solid #cfd3da;
      border-radius: 2px;
      margin-top: 10px;
      overflow-y: auto;
      .el-textarea {
        padding: 0px;
        box-sizing: border-box;
        textarea {
          border: none;
        }
      }
    }
  }
  .toneSelect_wrap {
    position: relative;
    .toneSelect {
      box-sizing: border-box;
      width: 282px;
      height: 558px;
      background: #f5f5f5;
      border: 1px solid #cfd3da;
      border-radius: 2px;
      margin-top: 10px;
      padding: 16px;

      .tone_item {
        box-sizing: border-box;
        width: 250px;
        height: 54px;
        background: #ffffff;
        border-radius: 4px;
        margin-bottom: 16px;
        border: 1px solid #fff;
        display: flex;
        align-items: center;
        cursor: pointer;
        .item_left {
          width: 34px;
          height: 34px;
          background: url("~@/assets/images/voice/tone_bg.png");
          background-size: 100% 100%;
          margin-left: 16px;
          margin-right: 8px;
        }
        .item_right {
          .title {
            font-size: 14px;
            color: #023243;
          }
          .subTitle {
            font-size: 12px;
            color: #6e7577;
            margin-top: 4px;
          }
        }
      }
      .active {
        border: 1px solid #1c95f0;
        .title {
          color: #1c95f0 !important;
        }
      }
    }

    .voiceConfig {
      box-sizing: border-box;
      width: 282px;
      height: 151px;
      background: #ffffff;
      border: 1px solid #cfd3da;
      border-radius: 2px;
      position: absolute;
      padding: 16px;
      bottom: 0;

      .config_item {
        display: flex;
        align-items: center;
        color: #333333;
        font-size: 14px;
        margin-bottom: 13px;
        .el-slider {
          width: 140px;
          height: 6px;
          margin: 0px 30px 0 15px;
          .el-slider__runway {
            height: 100%;
            margin: 0;
            .el-slider__button {
              width: 8px;
              height: 8px;
              background: #2774f7;
            }
          }
        }
        .el-select {
          width: 182px;
          height: 32px;
          background: #ffffff;

          border-radius: 2px;
          margin-left: 15px;
        }
      }
    }
  }
  .operation_wrap {
    width: 258px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    .audio_warp{
      width: 100%;
    }
    .wait {
      width: 200px;
      height: 92px;
      border-radius: 4px;
    }
    .startAudition,
    .Cloning {
      position: absolute;
      bottom: 5px;
      display: flex;
      width: 258px;
      height: 38px;
      background: url("~@/assets/images/voice/btn_gray.png");
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #ffffff;
      cursor: pointer;
    }
    .Cloning {
      background: #ffffff;
      border: 1px solid #2774f7;
      border-radius: 4px;
      font-size: 14px;
      color: #2774f7;
    }
    .complete {
      background: url("~@/assets/images/voice/btn_blue.png");
    }
  }
}
</style>