var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chartWrap"},[_c('div',{staticClass:"multi_modal_img",staticStyle:{"position":"relative"}},[(_vm.imageUrl)?_c('img',{staticClass:"temp_img",attrs:{"src":_vm.imageUrl}}):_vm._e(),_c('el-upload',{style:({
        position: 'absolute', 
        top: 0, 
        left: 0, 
        width: '100%', 
        height: '100%', 
        opacity: _vm.imageUrl ? 0 : 1
      }),attrs:{"action":"","drag":"","auto-upload":true,"show-file-list":false,"before-upload":_vm.handleBeforeUpload,"multiple":false,"limit":1,"accept":"image/x-png,image/jpeg"}},[_c('i',{staticClass:"el-icon-upload"}),_c('div',{staticClass:"el-upload__text"},[_vm._v("将图片拖到此处，或"),_c('em',[_vm._v("点击上传")])])])],1),_c('div',{staticClass:"result_content_wrap"},[_vm._m(0),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"result_content"},[(_vm.ProcessedImageUrl)?_c('el-image',{attrs:{"src":_vm.ProcessedImageUrl,"preview-src-list":[_vm.ProcessedImageUrl],"alt":""}}):_vm._e(),_c('p',[_vm._v(_vm._s(_vm.testResult))]),_c('el-button',{staticClass:"common-btn test_btn",attrs:{"type":"primary"},on:{"click":_vm.imageTest}},[_vm._v("测试")])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"result_top"},[_c('div',{staticClass:"vertical_line"}),_c('div',{staticClass:"title"},[_vm._v("识别结果")])])}]

export { render, staticRenderFns }